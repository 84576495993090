html,
body,
#root {
  height: 100%;
  font-family: "Nunito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: #212121;
}

:root {
  --tas-primary-color: #2cb052;
  --tas-primary-active-color: #009b3e;
  --tas-error-color: rgb(235, 87, 87);
  --tas-pro-color: #eabb2b;
  --tas-pro-color-invert: rgb(21, 68, 212);
  --tas-rad: 4px;
}

*,
:after,
:before {
  box-sizing: border-box;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

svg {
  overflow: hidden;
}
img,
svg {
  vertical-align: middle;
}

.MuiAutocomplete-popper {
  font-family: inherit;
}

.splash-screen {
  margin: auto;
}

.splash-screen svg path {
  stroke-dashoffset: 35;
  stroke-dasharray: 35;
  animation: draw 2s infinite alternate;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

h3 {
  color: var(--tas-primary-active-color);
}

body {
  margin: 0;
}

.appbar-logo {
  font-size: larger;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.appbar-logo img {
  margin: auto 0.5rem;
  height: 3rem;
}

.login-page-content .logo {
  margin: 0 auto 2rem auto;
  width: 4rem;
  height: 4rem;
  background: url("https://takeaseat.io/media/icons/tas-230526.svg") center
    center / contain no-repeat;
}

/* view-container*/
#page {
  width: 100%;
  position: relative;
}

.app-title {
  color: #0c2c15;
  font-size: x-large;
  font-weight: 700;
  margin: auto;
  text-align: center;
}

#view-container {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.05);
}

.chooseFileButton {
  background-color: white !important;
  color: var(--tas-primary-active-color, #138030) !important;
  border: 1px solid var(--tas-primary-active-color, #138030) !important;
  font-weight: normal !important;
  border-radius: var(--tas-rad) !important;
  cursor: pointer;
  font-size: unset !important;
}

/* Edit view */
.Collapsible__contentInner {
  border: 0;
  margin-bottom: 1px; /* Otherwise, does not unfold the collapsible when set to lazy*/
}

.app-menu {
  margin-bottom: 1rem;
  background: rgb(250, 250, 250);
  border-bottom: 1px solid rgb(225, 225, 225);
  width: 100%;
  position: sticky;
  z-index: 1000;
  top: 0;
}

.app-menu input[type="date"] {
  border: 0px !important;
}

.app-menu .first-row,
.app-menu .second-row {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: inherit;
}

.app-menu .first-row {
  height: 4.8rem;
  padding: 1rem;
  align-items: center;
  position: sticky;
  padding-block: 1rem;
  top: 0;
  z-index: 2;
  overflow: auto;
  overflow-y: hidden;
}

.app-menu .second-row {
  background-color: rgb(245, 245, 245);
  overflow: auto;
}

.app-menu > div > div:not(:last-child) {
  margin-right: 1rem;
  min-width: auto;
}

.app-menu button {
  min-width: 7rem;
}

.save-button {
  min-height: 2.4rem;
  padding: 0.3rem 0.75rem;
  border-radius: var(--tas-rad);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  gap: 0.5rem;
}

.save-button.saved {
  color: var(--tas-primary-color);
}

.save-button.unsaved {
  background-color: #ffd984;
  border: 2px solid #f17f37;
  cursor: pointer;
}

.app-menu:not(.preview):not(.survey) button,
.option-button {
  border-radius: var(--tas-rad);
  border: 0px;
  padding: 0.5rem 0.75rem;
  font-weight: bold;
  outline: 0px;
  cursor: pointer;
  background-color: rgb(225, 225, 225);
  color: #212121;
}

.app-menu.preview button {
  padding: 0.5rem 0.75rem;
}

.app-menu button:hover {
  background-color: rgb(225, 225, 225);
  color: #212121;
  box-shadow: none;
}

.app-menu button:focus {
  box-shadow: none;
}

.table-selector {
  display: flex;
  align-items: stretch;
}

.table-selector > div {
  padding: 0.5rem;
  cursor: pointer;
  min-width: 8rem;
  text-align: center;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-selector > div:hover {
  font-weight: 500;
  background: lightgrey;
}

.table-selector > div.selected {
  font-weight: 500;
  background: lightgrey;
}

.table-selector > div.selected::after,
.table-selector > div:hover::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 1px);
  border-left: 0.55rem solid transparent;
  border-right: 0.55rem solid transparent;
  border-bottom: 0.55rem solid white;
}

.hidden-item {
  background: rgba(250, 160, 90, 1);
  border-radius: var(--tas-rad) var(--tas-rad) 0rem 0rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.rainbow-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: 6px;
}

.rainbow {
  position: relative;
  z-index: 0;
  width: 100%;
  /* animation: rotateC 4s linear infinite; */
  height: 100%;
}

.rainbow::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: 0;
  top: 50%;
  width: 100%;
  /* min-height: 200%; */
  aspect-ratio: 1 / 1;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#00c9ff, #fffde4),
    linear-gradient(#00c9ff, #fffde4), linear-gradient(#fffde4, #00c9ff),
    linear-gradient(#fffde4, #00c9ff);

  background-image: linear-gradient(#ffd700, #ffd700),
    linear-gradient(#00e2bf, #00e2bf), linear-gradient(#ff66ff, #ff66ff),
    linear-gradient(#00cbff, #00cbff);
  transform: translate(0%, -50%);
}

.rainbow-container::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 12px;
  top: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background-color: var(--item-bg, #fff);
  border-radius: 4px;
  box-shadow: 0px 0px 6px 8px var(--item-bg, #fff);
}

.chefSelection {
  background: rgb(90, 146, 250);
  border-radius: var(--tas-rad) var(--tas-rad) 0rem 0rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  margin: -0.6rem -0.51rem 0.5rem -0.51rem;
  text-align: center;
}

.item .hidden-item {
  margin: -0.6rem -0.51rem 0.5rem -0.51rem;
  text-align: center;
}

.item-menu-options > div:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.item-menu-options svg {
  width: 0.8em;
  margin-right: 0.5rem;
}

.item-menu-options > div {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.item-menu-options div:hover {
  background: var(--tas-primary-color);
  color: white;
}

.fileUploader {
  display: flex;
  width: 12rem !important;
  border: 2px grey dashed;
  border-radius: var(--tas-rad);
  padding: 0rem 0.5rem;
  max-width: 100%;
}

.fileContainer {
  padding: unset !important;
  box-shadow: unset !important;
  margin: auto !important;
}

.item-content,
.item-header {
  position: relative;
}

.item-header {
  display: flex;
  align-items: center;
}

.name-container,
.description,
.secondary-fieds > div {
  margin-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.description {
  width: 50%;
}

.secondary-fieds > div {
  width: auto;
}

.item-menu.hide {
  margin-left: auto;
}

.item-header input {
  font-size: 120%;
  font-weight: 500;
}

.not-changeable {
  color: lightgray !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preview::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.preview {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input {
  outline: none;
}

.fullscreen {
  position: fixed;
  z-index: 1200;
  width: 100%;
  background: rgba(220, 220, 220, 0.85);
  min-height: 100vh;
  inset: 0;
  overflow: auto;
  backdrop-filter: blur(3px);
}

.fullscreen-title {
  padding: 1.5rem;
  text-align: center;
  font-size: larger;
  color: #023810;
}

.download-menu .format-select {
  display: flex;
}

.download-menu input {
  width: auto;
}

.fullscreen.light {
  inset: 0;
  background: rgba(220, 220, 220, 0.8);
}

.fullscreen .content {
  background: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  max-width: 95%;
  padding: 1rem;
  min-height: 15rem;
  border-radius: 4px;
}

.tas-title {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  color: var(--tas-primary-active-color, #138030);
}

.buttons {
  display: block;
  margin: auto;
}

.buttons > div {
  margin: auto 1rem;
  display: inline-block;
  max-width: 15rem;
}

.buttons a:hover {
  text-decoration: underline !important;
}

/* Identity */
.identity-content {
  width: fit-content;
  margin: auto;
}

input:disabled {
  border-color: white !important;
  color: currentColor;
}

.users-table th {
  vertical-align: bottom;
  width: 7rem;
  font-weight: 500;
  text-align: left;
}

.users-table th,
.users-table td {
  padding-inline: 0.5rem;
}

.users-table .email {
  text-overflow: ellipsis;
  white-space: pre;
  max-width: 30rem;
  overflow: hidden;
}

.user-modal {
  display: flex;
  /* use it because of bug with mui modal and clicking on checkbox*/
}

.user-modal .modal-content {
  margin: auto;
  padding: 1rem;
  max-width: 30rem;
}

.user-modal .field {
  margin-bottom: 0 !important;
  min-height: 3rem;
}

.user-modal .field div {
  display: inline-block;
}

.user-modal .field > div {
  min-width: 6rem;
  width: auto;
}

.user-modal input {
  width: 100%;
}

.urls {
  margin: 0.5rem auto;
  border: solid lightgray 1px;
  border-radius: var(--tas-rad);
  width: 100%;
  max-width: 58rem;
  background-color: white;
  padding: 1rem;
}

/* EditView */

.save-bar {
  display: flex;
  position: sticky;
  top: 0;
  margin-bottom: 1rem;
  z-index: 2;
  background-color: rgba(255, 255, 255, 1);
  padding: 0.5rem;
  box-shadow: 0px 6px 6px -2px rgb(220, 220, 220);
}

input {
  width: -webkit-fill-available;
  width: -moz-available;
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid lightgray !important;
}

.MuiTextField-root input {
  border-bottom: 0 !important;
}

td {
  margin-bottom: 1rem;
}

td > div {
  margin: 0.5rem 0.25rem 0.5rem 0;
}

.form-group {
  max-width: 10rem;
  margin-bottom: 0 !important;
}

.form-group input {
  text-align: center;
}

.edit-content {
  display: flex;
  flex-direction: column; /*CHANGED*/
  min-height: 80%;
}

.edit-content .sections {
  padding: 0;
  max-width: 100%;
  width: 58rem;
  margin: auto;
}

.settings .app-content,
.referral-content,
.booking .content,
.orders-reporting .app-content {
  margin: auto;
  padding: 1rem;
  padding-top: 0;
  width: 100%;
  max-width: 60rem;
}

.edit-content .app-content {
  display: flex;
  padding: 1rem;
  padding-top: 0;
  width: 100%;
  justify-content: space-evenly;
}

.item-edit-content {
  width: 100%;
  max-width: 50rem;
  background: grey;
  margin: auto;
  padding: 0.5rem;
  border-radius: 6px;
}

.item-edit-content > div {
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 4px;
}

.item-edit-content .field {
  margin-bottom: 1rem;
}

.preview .sections {
  padding: 0;
  font-size: smaller;
}

.contact-tracing-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-tracing-content .menu-line {
  padding: 1rem;
  margin: 0 auto;
  max-width: 100%;
  width: 55rem;
  margin-bottom: 1rem;
}

#contact-us {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid lightgray;
}

.analytics,
#contact-us,
.identity-content,
.attribution-page {
  width: 100%;
  max-width: 65rem;
  margin: 4rem auto;
}

section {
  width: 100%;
}

.subsection {
  border-radius: var(--tas-rad);
  margin-top: 4rem;
}

.subsection > div:not(.fullscreen) {
  color: grey;
}

.items,
.subsections {
  padding: 0;
  width: -webkit-fill-available;
}

li {
  list-style-type: none;
}

.items .item {
  border: 1px lightgrey solid;
  border-radius: var(--tas-rad);
  display: inline-block;
  background: white;
  vertical-align: top;
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
  /* box-shadow: 0px 0px 6px -2px rgb(0 0 0 / 30%); */
}

.item {
  position: relative;
}

.section-header,
.subsection-header {
  font-size: 125%;
  border-radius: 4px;
  border: 2px solid grey;
  padding: 1rem 0.5rem;
  background-color: white;
  flex-direction: column;
}

.section-header {
  border-radius: 4px;
  border: 1px solid #121212;
}

.expand-content {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #121212;
  border-top: none;
  cursor: pointer;
  text-align: center;
  z-index: 2;
  background-color: white;
}

.field.--subsection {
  margin-top: 1rem;
}

.field.--section {
  padding: 1rem 0;
}

.field-description {
  display: flex;
}

.field-description > span {
  padding: 0.5rem 0.75rem;
  width: 100%;
  border-radius: 2px;
  font-weight: 600;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  color: rgb(119, 119, 119);
}

.field-description > span:hover {
  background-color: grey;
  color: white;
}

.price-field .clear-icon,
.label-field .clear-icon,
.badge .clear-icon {
  position: relative;
}

.labels {
  display: inline;
}

.labels > li {
  display: inline-block;
  vertical-align: top;
}

.labels > li > span {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  height: 2.55rem;
}

.option {
  display: flex !important;
}

.labels,
.option {
  padding: 0;
}

.badge {
  margin-right: 0.5rem;
  padding: 0.25rem 0 0.25rem 0.25rem;
  font-size: 80%;
  font-weight: 600;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  background-color: #ebebeb;
}

#simple-popper {
  max-width: 90%;
  width: 15rem;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  background-color: white;
  box-shadow: 0px 0px 4px 2px #c4c4c4;
  margin-top: 0.25rem !important;
}

.icon-picker > div {
  display: inline-flex;
  margin: 0.5rem;
}

.tas-badge {
  display: inline-block;
  font-size: 80%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 0rem 0.2rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.prices,
.options,
.notes {
  margin-top: 0.5rem;
}

.images-row {
  text-align: left;
  max-width: 50%;
}

.images-row .thumbnail {
  margin-bottom: 0;
}

.images {
  display: inline-flex;
  overflow: auto;
  max-width: 100%;
}

.prices {
  display: flex;
}

.prices > .label,
.options > .label {
  color: grey;
}

.form-group > svg {
  margin: auto;
}

.labels > .form-group {
  margin-bottom: unset;
  max-width: 8rem;
  width: fit-content;
}

.clear-icon {
  position: absolute;
  top: 0;
  right: 0;
}

/* Login view */
#root {
  display: flex;
  flex-direction: column;
}

#login-page {
  width: 100%;
  height: 100%;
  display: flex;
}

.login-page-content {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--tas-rad);
}

/*TODO: refactor and create separate file*/

.login.error-message {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: red;
}

.login-field {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  position: relative;
}

.login-field > svg {
  margin-right: 0.5rem;
  position: absolute;
  left: 0.5rem;
}

.login-sep {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 2rem 0;
  color: lightgray;
  font-weight: 600;
}

.login-sep > span {
  padding-inline: 1rem;
  background-color: white;
  z-index: 2;
}

.login-sep::before {
  content: "";
  width: 100%;
  border-top: 1px solid lightgray;
  position: absolute;
  top: 50%;
  left: 0;
}

.login-google {
  height: 3rem;
  border: 2px solid #767676;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #767676;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.login-google:hover {
  box-shadow: 0px 0px 6px 0px grey;
}

.login-google > img {
  width: 1.7rem;
  height: 1.6rem;
  margin-right: 0.75rem;
}

/* Register */
.login-page-content {
  width: 30rem;
  background-color: white;
  padding: 2rem;
  padding-left: 2rem;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-page-content h1 {
  text-align: center;
  font-size: 160%;
  color: #212121;
}

.login-page-content h1 {
  margin-bottom: 2rem;
}

.login-page-content form label {
  margin-top: 1rem;
  clear: both;
  font-weight: 500;
  display: flex;
}

.login-page-content form input {
  height: 3rem;
  padding: 1rem;
  width: -webkit-fill-available;
  margin: 0;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.login-field input {
  padding-left: 2.5rem !important;
}

.login-page-content form button {
  margin-top: 1.5rem;
  height: 3rem;
  border: none;
  width: 100%;
  position: relative;
}

.login-page-content > div:last-child {
  text-align: center;
}

#reset-password-page .tas-button {
  width: 100%;
}

.loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.save-button .loader {
  filter: brightness(0.7);
}

/* Contact us */
#contact-us form > input[type="text"] {
  border: 1px solid lightgrey;
  margin-bottom: 1.5rem;
  background: white;
}

#contact-us form > textarea {
  border: 1px solid lightgrey;
}

/* Preview */
.preview-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 6rem;
}

.smoke-screen {
  position: absolute;
  inset: 0;
  background: rgba(170, 170, 170, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
}

.smoke-screen > div {
  display: block;
  font-size: 125%;
}

.preview-device {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.preview-device > div {
  margin: auto;
}

em {
  color: var(--tas-primary-active-color, #138030);
  font-style: normal;
  font-weight: bold;
}

.lang-selector {
  margin: 1rem auto;
  width: fit-content;
  background-color: white;
  cursor: pointer;
}

.lang-selector > div:hover {
  color: white;
  background-color: var(--tas-primary-active-color, #138030);
}

#page-subcontent {
  margin-left: 260px;
  height: 100%;
  min-height: 100vh;
}

.item-content > .Collapsible {
  margin-bottom: 0;
}

.is-open svg {
  transform: rotate(0deg);
  transition: transform 200ms;
}
.is-closed svg {
  transform: rotate(180deg);
  transition: transform 200ms;
}

.smartphone {
  width: 395px;
  height: 655px;
  transform: scale(0.75);
  transform-origin: top center;
}

.smartphone > div {
  position: relative;
  top: 53px;
  width: 351px;
  margin: auto;
}

.smartphone img {
  width: 395px;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  left: 0;
}

.smartphone iframe {
  position: relative;
  display: initial;
  border-radius: 0 0 2rem 2rem;
}

.no-access {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    -50deg,
    #fff,
    #fff 10px,
    #f5f5f5 10px,
    #f5f5f5 20px
  );
}

.no-access > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background: white;
  border-radius: var(--tas-rad);
  border: 2px solid #d3d3d3;
}

.no-access > div > div {
  padding: 0.5rem;
}

li.section {
  padding-inline: 0.5rem;
}

.design-app .toggle {
  display: none;
}

.small-screen {
  display: none;
}

@media (max-width: 880px) {
  /* Fit the menu on the full screen of the device */
  .preview-content:not(#survey-preview) {
    display: none;
  }

  .design-app .half.left {
    width: 100%;
  }

  .design-app .preview-content {
    position: absolute;
    right: 0;
  }

  .design-app .toggle {
    display: block;
    position: absolute;
    right: 0;
  }

  .small-screen {
    display: block;
  }

  .design-app .preview-content.show {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    height: 100%;
    position: fixed;
    inset: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
}

@media (max-width: 950px) {
  .lg-screen {
    display: none !important;
  }

  .edit-content .app-content {
    display: unset;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .app-menu {
    top: 3rem !important; /*if exactly 3rem, one pixel-line is showing*/
    margin-bottom: 0;
  }

  .app-menu button {
    min-width: 2rem !important;
  }

  .app-menu .tas-button.borderless {
    padding: 0;
    min-width: 4rem;
  }

  .buttons > div {
    margin: 0.5rem;
  }

  #page-subcontent {
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    margin-top: 3rem;
  }

  .login-page-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  html,
  body {
    overflow: hidden;
  }

  #root {
    overflow: scroll;
  }

  #page {
    display: unset;
  }

  #page-subcontent {
    flex-direction: column-reverse;
  }

  .app-title {
    font-weight: normal;
  }

  #view-container {
    margin-top: 0;
    margin-bottom: auto;
  }

  .identity-content {
    margin: 0.5rem;
  }

  .fileUploader {
    margin: auto;
  }

  .urls {
    padding: 0.3rem;
    display: block;
  }

  .identity-content {
    width: unset;
  }

  .edit.App {
    height: 100%;
  }

  .App {
    display: block;
  }

  .header {
    display: none;
  }

  .edit-content {
    display: block;
    top: 0;
    width: 100%;
  }

  .subsection {
    padding: 0 0.25rem;
  }

  .labels {
    display: block !important;
  }

  .labels li {
    display: inline-block;
  }

  .section-content {
    padding: 0;
  }

  .app-title {
    padding: 0;
    margin: auto;
  }

  .edit.left-column {
    border: none;
  }

  .edit.left-column .warning {
    height: fit-content;
    padding: 0;
  }

  #view-container > div:nth-last-child(2) {
    padding-bottom: 5rem;
  }

  .edit.right-column .smartphone {
    width: auto;
  }

  .edit.right-column .smartphone img,
  .edit.right-column .smartphone > div {
    left: 50%;
    transform: translate(-50%, 0px);
  }
}

/* Buttons */
.tas-button {
  padding: 0.6rem 1.5rem;
  background: var(--tas-primary-color, #2cb052);
  border-radius: var(--tas-rad, 4px);
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 700;
  background: linear-gradient(
    45deg,
    var(--tas-primary-active-color, #009b3e),
    var(--tas-primary-active-color, #009b3e) 33%,
    var(--tas-primary-color, #2cb052) 66%
  );
  background-size: 400% 100%;
  background-position: 99%;
  transition: background-position 500ms ease;
  text-align: center;
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.tas-button.thin {
  padding: 0.45rem 1.75rem;
}

.tas-button.active {
  background: var(--tas-primary-active-color);
}

.tas-button a {
  color: white;
}

.tas-button,
.tas-button > span {
  display: inline-block;
  vertical-align: middle;
}

.tas-button.wide {
  padding: 1rem 3rem;
}

.tas-button.pro {
  color: white;
  background: linear-gradient(
    45deg,
    #d6a615,
    #d6a615 33%,
    var(--tas-pro-color, #eabb2b) 66%
  );
  background-size: 400% 100%;
  background-position: 99%;
  transition: background-position 500ms ease;
}

.tas-button:hover {
  background-position: 0 150%;
  text-decoration: none;
}

.tas-button.outline {
  padding: 0.5rem 1.5rem;
  background: linear-gradient(
    45deg,
    rgb(211, 79, 173),
    rgb(211, 79, 173) 33%,
    black 66%
  );
  border: 2px solid rgb(211, 79, 173);
  color: white;
  background-size: 400% 100%;
  background-position: 99%;
  transition: background-position 500ms ease;
  position: relative;
  mix-blend-mode: difference;
}

.tas-button.danger.borderless {
  background: linear-gradient(
    45deg,
    rgb(20, 168, 168),
    rgb(20, 168, 168),
    black 66%
  );
  background-size: 400% 100%;
  background-position: 99%;
  transition: background-position 500ms ease;
  mix-blend-mode: difference;
}

.tas-button.danger.borderless > span {
  mix-blend-mode: difference;
  color: rgb(20, 168, 168);
  filter: invert(0);
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.tas-button.outline > span {
  mix-blend-mode: difference;
  color: rgb(211, 79, 173);
  filter: invert(0);
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.tas-button.outline:hover,
.tas-button.danger.borderless:hover {
  background-position: 0 150%;
}

.tas-button.danger,
.tas-button.danger > span {
  color: var(--tas-error-color, purple);
}

.tas-button.grey {
  background: linear-gradient(
    45deg,
    rgb(127, 127, 127),
    rgb(127, 127, 127),
    black 66%
  );
  background-size: 400% 100%;
  background-position: 99%;
  transition: background-position 500ms ease;
  mix-blend-mode: difference;
}

.tas-button.grey > span {
  mix-blend-mode: difference;
  color: rgb(127, 127, 127);
  filter: invert(0);
}

.tas-button.borderless {
  border: 0;
}

.tas-button.borderless a {
  color: inherit;
  text-decoration: none;
}

.clickable-icon {
  color: grey;
  cursor: pointer;
}

.clickable-icon:hover {
  color: #121212;
}

/* End button*/

.locked,
.unlocked {
  position: relative;
  padding-right: 1rem;
}

.unlocked::after {
  content: "✨";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-0.5rem, -50%);
  filter: invert(1);
}

.locked::after {
  content: "🔒";
  position: absolute;
  right: 0;
  top: 0;
  font-size: 75%;
}

.item-menu svg {
  border-radius: 50%;
}

button,
.button,
.positioning-arrows svg,
.item-menu svg,
svg.clear-icon,
.bubble-menu .menu-item,
.custom-options .back-arrow,
.clickable {
  cursor: pointer;
  transition: box-shadow 200ms ease;
}

button:hover,
.button:hover,
.positioning-arrows svg:hover,
.item-menu svg:hover,
svg.clear-icon:hover,
.bubble-menu .menu-item:hover,
.custom-options .back-arrow:hover,
.clickable:hover {
  box-shadow: 0px 0px 10px 3px lightgrey;
}

.deactivated {
  background-color: rgba(230, 230, 230);
  color: grey;
}

.confetti {
  position: absolute;
  inset: 0;
  width: 100%;
  pointer-events: none;
}

.loading {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009b3e;
}

.reason {
  padding: 1rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
}

.reason.selected {
  font-weight: bold;
  color: var(--tas-primary-color);
  box-shadow: 0px 0px 0px 2px var(--tas-primary-color);
}

@media (max-width: 841px) {
  .item-edit-content {
    margin-top: 0;
    height: 100%;
    background: white;
    border-radius: 0;
    padding: 0;
  }
}
